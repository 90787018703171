import React from "react";
// import '../../Css/Responsive.css'
// import '../../Css/Checkout.css'
// import '../../Css/Style.css'

const TopBar = () => {
  return (
    <div class="main-topbar">
      <p>
        Winterland Pakistan
      </p>
    </div>
  );
};

export default TopBar;
