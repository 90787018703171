import React from 'react'
// import '../../Css/Style.css'
// import '../../Css/Experiance.css'
// import '../../Css/Contact.css'

const Contact = () => {
    return (
        <>

            <div class="main-banner-inner">
                <div class="container">
                    <div class="inner-banner-txt">
                        <h1>Contact Us</h1>
                        <p>
                            Whether you have questions regarding Winterland, activities or any
                            suggestions, we’d love to hear from you! Use the form below to drop
                            us your mail.
                        </p>
                        <a href="/checkout-winterland-karachi"
                        >Book Now <i class="fa-solid fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <div class="contact-contact-box">
                            <div class="contact-sec-title">
                                <h2 class="title white-color">Get In Touch</h2>
                            </div>
                            <div class="contact-address-box">
                                <div class="address-icon">
                                    <i class="fa fa-phone"></i>
                                </div>
                                <div class="contact-address-text">
                                    <span class="label">Phone:</span>
                                    <a href="tel:123222-8888">0304-111-7669(SNOW)</a>
                                </div>
                            </div>
                            <div class="contact-address-box">
                                <div class="address-icon">
                                    <i class="fa fa-envelope"></i>
                                </div>
                                <div class="contact-address-text">
                                    <span class="label">Email:</span>
                                    <a href="#">info@winterland.pk</a>
                                </div>
                            </div>
                            <div class="contact-address-box">
                                <div class="address-icon">
                                    <i class="fa fa-map-marker"></i>
                                </div>
                                <div class="address-text">
                                    <span class="label">Karachi Address:</span>
                                    <div class="desc">
                                        PAF Museum (near Gate number 4) Sharah e Faisal – Faisal
                                        Cantonment, Karachi
                                    </div>
                                </div>
                            </div>

                            <div class="contact-address-box">
                                <div class="address-icon">
                                    <i class="fa fa-map-marker"></i>
                                </div>
                                <div class="address-text">
                                    <span class="label">Lahore Address:</span>
                                    <div class="desc">
                                        Winterland Lahore, Ghaznavi Block, Sector F, Bahria Town
                                        Lahore near Eiffel Tower & Rahia Goldplex Cinema.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <form class="overall-form">
                            <div class="row">
                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="your-name"
                                        placeholder="Your Name"
                                        name="your-name"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="your-surname"
                                        placeholder="Last Name"
                                        name="your-surname"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="your-email"
                                        placeholder="Email Address"
                                        name="your-email"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="your-subject"
                                        placeholder="Phone Number"
                                        name="your-subject"
                                    />
                                </div>
                                <div class="col-12">
                                    <textarea
                                        class="form-control"
                                        id="your-message"
                                        placeholder="Message"
                                        name="your-message"
                                        rows="5"
                                        required
                                    ></textarea>
                                </div>

                                <div class="cont-btn">
                                    <button type="submit" class="btn btn-dark w-100 fw-bold">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact