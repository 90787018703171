import React from 'react';
// import "../../../Css/Responsive.css";
// import "../../../Css/Checkout.css";
// import "../../../Css/Style.css";
import GlooveImg from "../../../images/guidance_wear-gloves.png"
import TimeImg from "../../../images/guidance_time.png"
import AccessImg from "../../../images/arcticons_access.png"
import JacketImg from "../../../images/tabler_jacket.png"

const Tabs = () => {
  return (
    <div class="ticketInclude">
    <div class="includeChild headingContainer blue">
      <h2 class="includeHeading">Ticket includes</h2>
    </div>
    <div class="includeChild yellow">
      <img class="inscludeImg" src={GlooveImg} alt="" />
      <p class="inscludeText">Gloves</p>
    </div>
    <div class="includeChild yellow">
      <img class="inscludeImg" src={TimeImg} alt="" />
      <p class="inscludeText">Unlimited Time</p>
    </div>
    <div class="includeChild yellow">
      <img class="inscludeImg" src={AccessImg} alt="" />
      <p class="inscludeText">Unlimited Access</p>
    </div>
    <div class="includeChild yellow">
      <img class="inscludeImg" src={JacketImg} alt="" />
      <p class="inscludeText">Jacket</p>
    </div>
  </div>
  )
}

export default Tabs