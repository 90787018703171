import axios from 'axios';
import { toast } from 'react-toastify';
const REACT_APP_API_URL = "http://103.121.121.202:9090/api/"


export const fetchProductKarachi = () => async dispatch => {
    try {
      const Response = await axios.get(`${REACT_APP_API_URL}Product/GetProductListInKarachi`);
      dispatch({ type: 'GET_CHECKOUT_KARACHI_SUCCESS', payload: Response.data.data.productList });
      dispatch({ type: 'GET_CHECKOUT_KARACHI_SLOT_SUCCESS', payload: Response.data.data.slotList });
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : 'Failed to Fetch Zone';
      dispatch({ type: 'GET_CHECKOUT_KARACHI_FAILURE', payload: errorMessage });
      dispatch({ type: 'GET_CHECKOUT_KARACHI_SLOT_FAILURE', payload: errorMessage });
    }
};

export const fetchProductLahore = () => async dispatch => {
    try {
      const Response = await axios.get(`${REACT_APP_API_URL}Product/GetProductListInLahore`);
      dispatch({ type: 'GET_CHECKOUT_LAHORE_SUCCESS', payload: Response.data.data.productList });
      dispatch({ type: 'GET_CHECKOUT_LAHORE_SLOT_SUCCESS', payload: Response.data.data.slotList });
     
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : 'Failed to Fetch Product';
      dispatch({ type: 'GET_CHECKOUT_LAHORE_FAILURE', payload: errorMessage });
      dispatch({ type: 'GET_CHECKOUT_LAHORE_SLOT_FAILURE', payload: errorMessage });
    }
}; 
  
export const postBookingData = (bookingData) => async (dispatch) => {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}CheckOut/addtocart`, bookingData);
    dispatch({ type: 'POST_BOOKING_SUCCESS', payload: response.data.data });
    localStorage.setItem('itemId', response.data.data.itemId)
    localStorage.setItem('customerTransactionId', response.data.data.customerTransactionId)
    localStorage.setItem('totalAmount', response.data.data.totalAmount)
    return true
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : 'Failed to create booking';
    dispatch({ type: 'POST_BOOKING_FAILURE', payload: errorMessage });
    toast.error("Failed!")
    return false
  }
};
export const postBookingDataLah = (bookingData) => async (dispatch) => {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}CheckOut/addtocart`, bookingData);
    dispatch({ type: 'POST_BOOKING_LAHORE_SUCCESS', payload: response.data.data });
  
    
    return true
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : 'Failed to create booking';
    dispatch({ type: 'POST_BOOKING_LAHORE_FAILURE', payload: errorMessage });
    toast.error("Failed!")
    return false
  }
};

export const placeOrder = (userName, mobileNumber) => async (dispatch) => {
  try {
    const description = "xyz"
    const amount = parseFloat(localStorage.getItem('totalAmount'));
    const item = localStorage.getItem('itemId')
    const customerTransactionId = localStorage.getItem('customerTransactionId');

    const response = await axios.post(`${REACT_APP_API_URL}CheckOut/placeOrder`, {userName, mobileNumber, customerTransactionId, item, amount, description});

    // If the response is successful, open the response URL in the same tab
    window.location.href = response.data.data;

    // Remove items from local storage
    localStorage.removeItem('totalAmount')
    localStorage.removeItem('itemId')
    localStorage.removeItem('customerTransactionId')

    dispatch({ type: 'PLACE_ORDER_SUCCESS', payload: response.data.data });
    return true;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : 'Failed to create booking';
    dispatch({ type: 'PLACE_ORDER_FAILURE', payload: errorMessage });
    toast.error("Failed!")
    return false;
  }
};


export const placeOrderLah = (userName, mobileNumber) => async (dispatch) => {
  try {
    const description = "xyz";
    const amount = parseFloat(localStorage.getItem('totalAmountL'));
    const item = localStorage.getItem('itemIdL');
    const customerTransactionId = localStorage.getItem('customerTransactionIdL');

    const response = await axios.post(`${REACT_APP_API_URL}CheckOut/placeOrder`, { userName, mobileNumber, customerTransactionId, item, amount, description });
    
    // Open link in the same tab
    window.location.href = response.data.data;

    // If the response is successful, remove items from local storage
    localStorage.removeItem('totalAmountL');
    localStorage.removeItem('itemIdL');
    localStorage.removeItem('customerTransactionIdL');

    dispatch({ type: 'PLACE_ORDER_LAHORE_SUCCESS', payload: response.data.data });
    return true;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : 'Failed to create booking';
    dispatch({ type: 'PLACE_ORDER_LAHORE_FAILURE', payload: errorMessage });
    toast.error("Failed!");
    return false;
  }
};


