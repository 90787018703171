import React, {useEffect} from 'react';
import Banner from '../Layout/Checkout/Banner';
import Tabs from '../Layout/Checkout/Tabs';
import BookingSysLahore from '../Layout/Checkout/BookingSysLahore';
import Accordion from './../Layout/Checkout/Accordion';


const BookNowLahore = () => {


 
  return (
    <>
      <Banner />
      <Tabs />
      <BookingSysLahore />
      <Accordion />
    </>
  )
}

export default BookNowLahore