import React, { Component } from 'react';
import BannerImg from "../../../images/DSC_6327-Enhanced-NR 2.png";

class Banner extends Component {
  render() {
    return (
      <>
        <div class="banner-video">
          <div class="container">
            <div
              class="vpop"
              data-type="youtube"
              data-id="6xcG6ttMDVY"
              data-autoplay="true"
            >
              <i class="fa-sharp fa-thin fa-circle-play"></i>
            </div>
            <div class="video">
              <img
                class="videoBannerImg"
                src={BannerImg}
                alt=""
              />
            </div>
            <div class="ticketDetails">
              <div class="offSale">10% Off</div>
              <div class="ticketContent">
                <h2 class="ticketHeading">Checkout</h2>
                <p class="ticketText">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum
                  magnam voluptatem cumque dicta accusamus et ea omnis adipisci?
                  Alias, accusamus.
                </p>
              </div>
              <div class="TicketPrice">
                <p class="currentPrice">Rs: 500</p>
                <p class="discountedPrice">Starting from 999 PKR</p>
              </div>
              <img class="lines" src="images/lines.svg" alt="" />
            </div>
          </div>
        </div>
        <div id="video-popup-overlay"></div>

        <div id="video-popup-container">
          <div id="video-popup-close" class="fade">&#10006;</div>
          <div id="video-popup-iframe-container">
            <iframe
              id="video-popup-iframe"
              src=""
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </>
    )
  }
}

export default Banner;