import React, { Component } from 'react';
import Exp1 from "../../images/experience/zipline/one.jpg";
import Exp2 from "../../images/experience/marry-snow-round/one.jpg";
import Exp3 from "../../images/experience/bumper-cars/one.jpg";
import Exp4 from "../../images/experience/zorb-ball/one.jpg";
import Exp5 from "../../images/experience/ice-cyling/one.jpg";
import Exp6 from "../../images/experience/live-snowfall/One.jpg";
import { Link } from 'react-router-dom';
// import '../../Css/Style.css'
// import '../../Css/Experiance.css'
// import '../../Css/Responsive.css'

class ExperianceContent extends Component {
    render() {
        return (
            <>
                <div class="main-banner-inner">
                    <div class="container">
                        <div class="inner-banner-txt">
                            <h1>Experiences</h1>
                            <p>With icy rides and live snow fall, there is something for everyone at Winterland! We offer a frosty escape where you can make lasting memories and explore thrilling activities like zipline, merry snow rounds, extreme slides and so much more!
                            </p>
                            <Link href="#">Book Now <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div class="experience-sec-main">
                    <div class="experience-boxes-cont first-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-imge">
                                        <img src={Exp1} alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-info">
                                        <h3>Zipline</h3>
                                        <p>
                                            Glide 12 feet above the snow-covered landscape, embracing the
                                            magical atmosphere and creating unforgettable memories. Feel
                                            the exhilarating chill of the air as you soar through our
                                            Zipline.
                                        </p>
                                        <Link to="/zipline"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="experience-boxes-cont">
                        <div class="experience-boxes-cont second-col-aftr">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="experience-info even-box">
                                            <h3>Merry Snow Round</h3>
                                            <p>
                                                This inflatable tube ride spins around a rotating pole on an
                                                ice platform, offering a classic merry-go-round experience
                                                with a snowy twist.
                                            </p>
                                            <Link to="/merysnowround"
                                            >Explore More <i class="fa-solid fa-arrow-right"></i
                                            ></Link>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="experience-imge even-box">
                                            <img src={Exp2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="experience-boxes-cont third-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-imge">
                                        <img src="images/experience/extreme-slides/one.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-info">
                                        <h3>Extreme Slides</h3>
                                        <p>
                                            Gear up for the icy fun of icy slides! Feel the speed, embrace the excitement, and slide into unforgettable memories!
                                        </p>
                                        <Link to="/extremeslides"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="experience-boxes-cont fourth-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-info even-box">
                                        <h3>Bumper Cars</h3>
                                        <p>
                                            Compete against friends or family in this frosty twist on our bumper car. With room for two, bump, slide, and spin your way across the slippery surface, adding an extra challenge to the fun!
                                        </p>
                                        <Link to="/bumpercars"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-imge even-box">
                                        <img src={Exp3} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="experience-boxes-cont fifth-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-imge">
                                        <img src={Exp4} alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-info">
                                        <h3>Zorb Ball</h3>
                                        <p>
                                            Embark on an unforgettable journey with thrilling Zorb ball experience! Climb inside our giant, inflatable sphere and get ready to roll, bounce, and spin your way through a winter wonderland
                                        </p>
                                        <Link to="/zorbball"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="experience-boxes-cont sixth-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-info even-box">
                                        <h3>Ice Cycling</h3>
                                        <p>
                                            Brace yourself up for a frosty padding adventure on the Ice Cycling track! Get your heart pumping and embrace the chill while enjoying a one-of-a-kind winter workout!
                                        </p>
                                        <Link to="/icecycling"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-imge even-box">
                                        <img src={Exp5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="experience-boxes-cont seventh-col-aftr">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="experience-imge">
                                        <img src={Exp6} alt="" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="experience-info">
                                        <h3>Live Snow Fall</h3>
                                        <p>
                                            Dive into heaps of soft, white snow for epic snowball fights and snowman building. Rediscover the joy of winter as you frolic in the snow and soak in the enchanting winter wonderland scenery.
                                        </p>
                                        <Link to="/livesnowfall"
                                        >Explore More <i class="fa-solid fa-arrow-right"></i
                                        ></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
         
        </>
    )
    }
}

export default ExperianceContent