import React from 'react';
// import "../../Css/Responsive.css";
// import "../../Css/Checkout.css";
// import "../../Css/Style.css";

const CopyRight = () => {
  return (
    <div class="bottm-line-text">
    <div class="container">
      <p>
        © Copyright 2023 Winterland Pakistan | Designed & Developed by Eyecon Conultant
      </p>
    </div>
  </div>
  )
}

export default CopyRight