import React, { Component } from 'react';
import $ from 'jquery';

class Map extends Component {

  componentDidMount() {
    $(document).ready(function () {
      // Initial setup
      $('.map-tab:first').addClass('active');
      $('#map-tab1').addClass('active');

      // map-Tab click event using event delegation
      $('.map-tabs-container').on('click', '.map-tab', function (event) {
        var tabId = $(this).data('tab'); // Corrected the data attribute name
        $('.map-tab').removeClass('active');
        $(this).addClass('active');
        $('.map-slide').removeClass('active');
        $('#' + tabId).addClass('active'); // Corrected the variable name here as well
      });
    });

  }

  render() {
    return (
      <>
        <div class="map-upr-divider"></div>
        <div class="map-sec-stat">
          <div class="map-tabs-container">
            <div class="map-tab" data-tab="map-tab1">
              <a href="#">Winterland Karachi</a>
              <div class="map-tab-text">
                <ul>
                  <li>
                    <div class="local-mge">
                      <img src="images/map.svg" alt="" />
                    </div>
                    <div class="local-text">
                      PAF Museum (near Gate number 4) Sharah e Faisal – Faisal
                      Cantonment, Karachi
                    </div>
                  </li>

                  <li>
                    <div class="local-mge">
                      <img src="images/timing.svg" alt="" />
                    </div>
                    <div class="local-text">Timings: 3PM to 11PM</div>
                  </li>

                  <li>
                    <div class="local-mge">
                      <img src="images/phone.svg" alt="" />
                    </div>
                    <div class="local-text">0304-111-7669(SNOW)</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="map-tab" data-tab="map-tab2">
              <a href="#">Winterland Lahore</a>
              <div class="map-tab-text">
                <ul>
                  <li>
                    <div class="local-mge">
                      <img src="images/map.svg" alt="" />
                    </div>
                    <div class="local-text">
                      Winterland Lahore, Ghaznavi Block, Sector F, Bahria Town
                      Lahore near Eiffel Tower & Rahia Goldplex Cinema.
                    </div>
                  </li>

                  <li>
                    <div class="local-mge">
                      <img src="images/timing.svg" alt="" />
                    </div>
                    <div class="local-text">Timings: 3PM to 11PM</div>
                  </li>

                  <li>
                    <div class="local-mge">
                      <img src="images/phone.svg" alt="" />
                    </div>
                    <div class="local-text">0304-111-7669(SNOW)</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="map-slider">
            <div class="map-slide" id="map-tab1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.8796738204287!2d67.09122029121922!3d24.86795888804695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33949aafa36dd%3A0xf487fcb85948755d!2sWinter%20Land%20-%20Karachi!5e0!3m2!1sen!2s!4v1712309565311!5m2!1sen!2s"
                width="100%" height="550" style={{border: 0}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="map-slide" id="map-tab2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.3044171908737!2d74.17022517685415!3d31.35057775550902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3918fff4f12bc9ab%3A0x14abbd77e2839eb3!2sWinter%20Land%20-%20Lahore!5e0!3m2!1sen!2s!4v1712309499535!5m2!1sen!2s"
                width="100%" height="550" style={{border: 0}} allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </>
    )
  }

}

export default Map;
