import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Logo from '../../images/logo.svg';
import $ from "jquery";

class Navbar extends Component {
    componentDidMount() {
        // jQuery functionality can be used here
        $('.booking-boxes-container').hide();

        $('#click-to-toggle').click(function () {
            $('.booking-boxes-container').toggle();
        });

        $(document).ready(function () {
            $('.dropdown-toggle').click(function () {
                $(this).next('.dropdown-menu').toggleClass('show')
            })

            $(document).on('click', function (event) {
                if (!$(event.target).closest('.dropdown').length) {
                    $('.dropdown-menu').removeClass('show')
                }
            })
        })
    }

    componentWillUnmount() {
        $('#click-to-toggle').off('click');
    }

    render() {
        return (
            <nav className="navbar sticky-top navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} alt="" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars"></i>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto w-100 justify-content-center">
                            <li className="nav-item active">
                                <Link to='/' className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link">About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/experiance" className="nav-link">Experiences</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">Offers</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">Corporate Booking</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">Gifts</Link>
                            </li>
                        </ul>
          </div>

          <div className="nav-booknow-button">
            <button className="click-to-toggle" id="click-to-toggle">BOOK NOW</button>
            <div className="booking-boxes-container" id="booking-boxes-container">
              <div className="booking-boxes">
                <h4 className="booking-h4">Winterland Karachi</h4>
                <p className="booking-para">
                  Winterland Karachi invites you to immerse yourself in a chilly retreat!
                </p>
                <Link to="/checkout-winterland-karachi">Book Now</Link>
              </div>
              <div className="booking-boxes">
                <h4 className="booking-h4">Winterland Lahore</h4>
                <p className="booking-para">
                  Winterland Lahore invites you to a frosty wonderland of fun and excitement!
                </p>
                <Link to="/checkout-winterland-lahore">Book Now</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

    );
  }
}

export default Navbar;
