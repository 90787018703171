import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Pages/Layout/Navbar';
import TopBar from './Pages/Layout/TopBar';
import Map from './Pages/Layout/Map';
import Footer from './Pages/Layout/Footer';
import CopyRight from './Pages/Layout/CopyRight';
import Experiance from './Pages/Components/Experiance';
import BookNow from './Pages/Components/BookNow';
import BookNowLahore from './Pages/Components/BookNowLahore';
import Zipline from './Pages/Layout/ImageViewer/Zipline';
import MerySnowRound from './Pages/Layout/ImageViewer/MerySnowRound';
import ExtremeSlides from './Pages/Layout/ImageViewer/ExtremeSlides';
import BumperCars from './Pages/Layout/ImageViewer/BumperCars';
import ZorbBall from './Pages/Layout/ImageViewer/ZorbBall';
import IceCycling from './Pages/Layout/ImageViewer/IceCycling';
import LiveSnowFall from './Pages/Layout/ImageViewer/LiveSnowFall';
import Faqs from './Pages/Components/Faqs';
import TermsnCondition from './Pages/Components/TermsnCondition';
import Safety from './Pages/Components/Safety';
import PrivacyPolicy from './Pages/Components/PrivacyPolicy';
import Contact from './Pages/Components/Contact';

const Home = lazy(() => import('./Pages/Components/Home'));
const About = lazy(() => import('./Pages/Components/About'));

function App() {
  return (
    <Router> {/* Make sure Router wraps all Route and Link components */}
      <TopBar />
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/experiance" element={<Experiance />} />
          <Route path="/checkout-winterland-karachi" element={<BookNow />} />
          <Route path="/checkout-winterland-lahore" element={<BookNowLahore />} />
          <Route path="/zipline" element={<Zipline />} />
          <Route path="/merysnowround" element={<MerySnowRound />} />
          <Route path="/extremeslides" element={<ExtremeSlides />} />
          <Route path="/bumpercars" element={<BumperCars />} />
          <Route path="/zorbball" element={<ZorbBall />} />
          <Route path="/icecycling" element={<IceCycling />} />
          <Route path="/livesnowfall" element={<LiveSnowFall />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/terms-and-condition" element={<TermsnCondition />} />
          <Route path="/safety-and-satisfaction" element={<Safety />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
      <Map />
      <Footer />
      <CopyRight />
    </Router>
  );
}

export default App;