import React, { Component } from 'react';
import $ from 'jquery';
// import '../../../Css/Experiance.css';
import pic1 from "../../../images/experience/marry-snow-round/one.jpg"

class MerySnowRound extends Component {

    componentDidMount() {
        $(document).ready(() => {
            $(".filters-cta").click(() => {
                let images = $(".filter-image-container img");
                images.each((index, img) => {
                    img.classList.remove("filter-preset-1", "filter-preset-2", "filter-preset-3", "filter-preset-4", "filter-preset-5", "filter-preset-6", "filter-preset-7", "filter-preset-8", "filter-preset-9", "filter-preset-10", "filter-preset-11", "filter-preset-12", "filter-preset-13", "filter-preset-14", "filter-preset-15", "filter-preset-16");
                    img.classList.add(getRandomPreset());
                });
            });
        });

        function getRandomPreset() {
            const presets = [
                "filter-preset-1",
                "filter-preset-2",
                "filter-preset-3",
                "filter-preset-4",
                "filter-preset-5",
                "filter-preset-6",
                "filter-preset-7",
                "filter-preset-8",
                "filter-preset-9",
                "filter-preset-10",
                "filter-preset-11",
                "filter-preset-12",
                "filter-preset-13",
                "filter-preset-14",
                "filter-preset-15",
                "filter-preset-16",
            ];
            const randomIndex = Math.floor(Math.random() * presets.length);
            return presets[randomIndex];
        }
    }

    render() {
        return (
            <div class="mansory-gallery">
                <div class="container">
                    <div class="filter-image-container">
                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/one.jpg"
                        >
                            <img src={pic1} alt="" />
                        </a>

                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/two.jpg"
                        >
                            <img src="images/experience/marry-snow-round/two.jpg" alt="" />
                        </a>

                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/three.jpg"
                        >
                            <img src="images/experience/marry-snow-round/three.jpg" alt="" />
                        </a>

                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/four.jpg"
                        >
                            <img src="images/experience/marry-snow-round/four.jpg" alt="" />
                        </a>

                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/five.jpg"
                        >
                            <img src="images/experience/marry-snow-round/five.jpg" alt="" />
                        </a>

                        <a
                            data-fancybox="gallery"
                            href="images/experience/marry-snow-round/six.jpg"
                        >
                            <img src="images/experience/marry-snow-round/six.jpg" alt="" />
                        </a>


                    </div>
                </div>
            </div>
        )
    }
}

export default MerySnowRound