import React from 'react'
// import '../../Css/Style.css'
// import '../../Css/Experiance.css'
// import '../../Css/privacy-policy.css'

const TermsnCondition = () => {
  return (
    <>


      <div class="main-banner-inner">
        <div class="container">
          <div class="inner-banner-txt">
            <h1>Terms And Conditions</h1>
            <br />
            <a href="/checkout-winterland-karachi"
            >Book Now <i class="fa-solid fa-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="content-sec">
        <div class="container">
          <div class="overall-txt-policy">
            <p>
              Winterland ticket holders are subject to the following Terms &
              Conditions:
            </p>
            <div class="list-style-policy">
              <ul>
                <li>
                  Admission ticket permits admission for one individual only
                </li>
                <li>
                  Children below 2 years are exempted from ticket charges & must
                  be accompanied by a guardian
                </li>
                <li>Weekends are only for families.</li>
                <li>Report 10 minutes prior to the session at Booking Office</li>
                <li>
                  Jackets with hood and Gloves will be provided with the purchase
                  of ticket
                </li>
                <li>
                  All accessories are not of accurate sizes and are subject to
                  availability
                </li>
                <li>
                  The following articles are strictly prohibited: Eatables
                  (Including Gutkha, Pan-Masala, Chewing Gum, Chocolate, Chips),
                  Knife, and Cigarette, Lighter, Match-Box, Firearms and all kind
                  of inflammable objects.
                </li>
                <li>
                  Winterland will not be responsible for any loss, theft or any
                  damage of any of your valuables
                </li>
                <li>Total duration of snow room is 60 minutes</li>
                <li>
                  Rights of management are reserved by the management of
                  Winterland
                </li>
                <li>
                  Winterland may take photographs or recordings of any visitor for
                  any purpose, without prior approval or compensation
                </li>
                <li>
                  The ticket must be in your possession during entire session.
                  Management reserves the rights to deny entry if you are unable
                  to provide a valid ticket for the show
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TermsnCondition