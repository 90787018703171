import React from 'react'
import ExperianceContent from '../Layout/ExperianceContent'


const Experiance = () => {
  return (
    <>
      <ExperianceContent />
    </>
  )
}

export default Experiance