import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Css/Style.css'
import './Css/Responsive.css'
import './Css/Checkout.css'
import './Css/Experiance.css'
import './Css/privacy-policy.css'
import './Css/Contact.css'
// import 'react-toastify/dist/ReactToastify.css';

import { Provider as ReduxProvider } from 'react-redux';

import { store } from './redux/store';
// import { ToastContainer } from 'react-toastify'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
        <App />
        {/* <ToastContainer /> */}
    </ReduxProvider>
  </React.StrictMode>
);