import React, { Component } from 'react';
import InstaIcon from "../../images/insta.svg";
import FbIcon from "../../images/facebook.svg";
import Logo from "../../images/logo.svg"
import Reel1 from "../../images/insta/insta-reel1.jpg";
import Reel2 from "../../images/insta/insta-reel2.jpg";
import Reel3 from "../../images/insta/insta-reel3.jpg";
import Reel4 from "../../images/insta/insta-reel4.jpg";
import Reel5 from "../../images/insta/insta-reel5.jpg";
import Reel6 from "../../images/insta/insta-reel6.jpg";
import $ from 'jquery'
import { Link } from 'react-router-dom'; 

class Footer extends Component {

  componentDidMount() {

    $(document).ready(function () {
      // Initially set background gradient based on the active tab
      updateBackground();

      // Change background gradient on tab change with fade animation
      $('#myTab a').on('click', function (e) {
        e.preventDefault();
        var tabId = $(this).attr('aria-controls');
        var gradient;
        switch (tabId) {
          case 'home':
            gradient = 'linear-gradient(to right, #64308F, #26A7DF)'; // Home tab gradient
            break;
          case 'profile':
            gradient = 'linear-gradient(to right, #FFBB02, #E84297)'; // Profile tab gradient
            break;
          default:
            gradient = 'linear-gradient(to right, #64308F, #26A7DF)'; // Default gradient
        }
        $('.fade-bg').css('background', gradient).addClass('active');
        setTimeout(function () {
          updateBackground(gradient);
          $('.fade-bg').removeClass('active');
        }, 500); // Same duration as the background transition
        $(this).tab('show');
      });

      // Function to update background gradient
      function updateBackground(gradient) {
        if (!gradient) {
          gradient = 'linear-gradient(to right, #64308F, #26A7DF)'; // Default gradient
        }
        $('.section-footer').css('background', gradient);
      }
    });

  }

  render() {
    return (
      <section class="section-footer">
        <div class="fade-bg"></div>
        <div class="container">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                aria-selected="true"><img src={InstaIcon} alt="" /></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                aria-selected="false"><img src={FbIcon} alt="" /></a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <h3>Instagram</h3>
              <div class="overall-reels">
                <div class="row">
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/C2Zkw7Tr-Th/"><img
                        src={Reel1} alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/CylEb9MMndV/"><img
                        src={Reel2} alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/CtI1oWNpZcp/"><img
                        src={Reel3} alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/C1kDpIPC72H/"><img
                        src={Reel4} alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/C1jSnSrC2wy/"><img
                        src={Reel5} alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.instagram.com/reel/C3zXQrpBhHj/"><img
                        src={Reel6} alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="footer-nav-line">
                <div class="brand-logo">
                  <img src={Logo} alt="" />
                </div>
                <div class="nav-fotr-main">
                  <ul class="fotr-inner-menu">
                    <li><Link to="/faqs">FAQ’s</Link></li>
                    <li>
                      <Link to="/terms-and-condition">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="/safety-and-satisfaction">Safety And Satisfaction
                      </Link>
                    </li>
                    <li><Link to="/privacy-policy">Privacy Policy </Link></li>
                    <li><Link to="/contact">Business Inquiries</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <h3>Facebook</h3>
              <div class="overall-reels">
                <div class="row">
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank"
                        href="https://www.facebook.com/reel/2125525024456356/?s=fb_shorts_tab&stack_idx=0"><img
                          src="images/facebook/facevideo1.jpg" alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank"
                        href="https://www.facebook.com/reel/1011259010235599/?s=fb_shorts_tab&stack_idx=0"><img
                          src="images/facebook/facevideo2.jpg" alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank"
                        href="https://www.facebook.com/reel/6360798194003677/?s=fb_shorts_tab&stack_idx=0"><img
                          src="images/facebook/facevideo3.jpg" alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.facebook.com/reel/1209209506444341"><img
                        src="images/facebook/facevideo4.jpg" alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.facebook.com/reel/1515778972574944"><img
                        src="images/facebook/facevideo5.jpg" alt="" /></a>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6">
                    <div class="inner-post-tabs">
                      <a target="_blank" href="https://www.facebook.com/reel/1430579404460431"><img
                        src="images/facebook/facevideo6.jpg" alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-nav-line">
                <div class="brand-logo">
                  <img src="images/logo.svg" alt="" />
                </div>
                <div class="nav-fotr-main">
                  <ul class="fotr-inner-menu">
                    <li><Link to="/faqs">FAQ’s</Link></li>
                    <li>
                      <Link to="/terms-and-condition">Terms and conditions</Link>
                    </li>
                    <li><Link to="#">Safety rules</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/contact-us">Business Inquiries</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer