import React, {useEffect} from 'react';
import Banner from '../Layout/Checkout/Banner';
import Tabs from '../Layout/Checkout/Tabs';
import BookingSys from '../Layout/Checkout/BookingSys';
import Accordion from './../Layout/Checkout/Accordion';


const BookNow = () => {


 
  return (
    <>
      <Banner />
      <Tabs />
      <BookingSys />
      <Accordion />
    </>
  )
}

export default BookNow