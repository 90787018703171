import React from 'react'
import Accordion from '../Layout/Checkout/Accordion'
// import '../../Css/Style.css'
// import '../../Css/Experiance.css'
// import '../../Css/Responsive.css'

const Faqs = () => {
    return (
        <>
            <div class="main-banner-inner">
      <div class="container">
        <div class="inner-banner-txt">
          <h1>Frequently Asked Questions</h1>
          <p>
            Have questions about Winterland? Check out our FAQ for quick answers
            to common inquiries about tickets, activities, hours, and more!
          </p>
          <a href="/checkout-winterland-karachi"
            >Book Now <i class="fa-solid fa-arrow-right"></i
          ></a>
        </div>
      </div>
    </div>

            <Accordion />
        </>
    )
}

export default Faqs