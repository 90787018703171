import React from 'react';
// import "../../../Css/Responsive.css";
// import "../../../Css/Checkout.css";
// import "../../../Css/Style.css";
import AccordionImg from "../../../images/accordian-left.png"

const Accordion = () => {
  return (
    <div class="accordian-main-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="accordian-mge">
            <img src={AccordionImg} alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="accordian-items">
            <h2>Frequently Asked Questions</h2>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="card">
                  <div
                    class="card-header"
                    id="headingOne"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <h4>
                      <span
                        >Q: What should I wear to the indoor snow park?</span
                      >
                      <i class="fas fa-chevron-up"></i>
                    </h4>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      A: It is suggested to wear warm, comfortable clothing
                      such as long pants, a long-sleeved shirt, and closed-toe
                      shoes. Additionally, don't forget to bring socks.
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="card">
                  <div
                    class="card-header collapsed"
                    id="headingTwo"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h4>
                      <span
                        >Q: What should I wear to the indoor snow park?</span
                      >
                      <i class="fas fa-chevron-down"></i>
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      A: It is suggested to wear warm, comfortable clothing
                      such as long pants, a long-sleeved shirt, and closed-toe
                      shoes. Additionally, don't forget to bring socks.
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="card">
                  <div
                    class="card-header collapsed"
                    id="headingThree"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <h4>
                      <span
                        >Q: What should I wear to the indoor snow park?</span
                      >
                      <i class="fas fa-chevron-down"></i>
                    </h4>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      A: It is suggested to wear warm, comfortable clothing
                      such as long pants, a long-sleeved shirt, and closed-toe
                      shoes. Additionally, don't forget to bring socks.
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="card">
                  <div
                    class="card-header collapsed"
                    id="headingfourth"
                    data-toggle="collapse"
                    data-target="#collapsefourth"
                    aria-expanded="false"
                    aria-controls="collapsefourth"
                  >
                    <h4>
                      <span
                        >Q: What should I wear to the indoor snow park?</span
                      >
                      <i class="fas fa-chevron-down"></i>
                    </h4>
                  </div>
                  <div
                    id="collapsefourth"
                    class="collapse"
                    aria-labelledby="headingfourth"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      A: It is suggested to wear warm, comfortable clothing
                      such as long pants, a long-sleeved shirt, and closed-toe
                      shoes. Additionally, don't forget to bring socks.
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="card">
                  <div
                    class="card-header collapsed"
                    id="headingfourth"
                    data-toggle="collapse"
                    data-target="#collapsefourth"
                    aria-expanded="false"
                    aria-controls="collapsefourth"
                  >
                    <h4>
                      <span
                        >Q: What should I wear to the indoor snow park?</span
                      >
                      <i class="fas fa-chevron-down"></i>
                    </h4>
                  </div>
                  <div
                    id="collapsefourth"
                    class="collapse"
                    aria-labelledby="headingfourth"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      A: It is suggested to wear warm, comfortable clothing
                      such as long pants, a long-sleeved shirt, and closed-toe
                      shoes. Additionally, don't forget to bring socks.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Accordion