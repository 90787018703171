import React from 'react'
// import '../../Css/Style.css'
// import '../../Css/Experiance.css'
// import '../../Css/privacy-policy.css'

const Safety = () => {
    return (
        <>
            <div class="main-banner-inner">
                <div class="container">
                    <div class="inner-banner-txt">
                        <h1>Safety And Satisfaction</h1>
                        <br />
                        <a href="/checkout-winterland-karachi"
                        >Book Now <i class="fa-solid fa-arrow-right"></i
                        ></a>
                    </div>
                </div>
            </div>
            <div class="content-sec">
                <div class="container">
                    <div class="overall-txt-policy">
                        <h3>Safety Rules</h3>
                        <p>
                            WinterLand ticket holders are subject to the following Safety Rules:
                        </p>
                        <div class="list-style-policy">
                            <ul>
                                <li>
                                    It is compulsory to wear long pants and socks to stay warm in
                                    subzero temperature
                                </li>
                                <li>
                                    It is advised not to enter snow chamber in case of; pregnancy,
                                    suffering from any medical problems, cold, allergy or feeling
                                    sick or dizzy
                                </li>
                                <li>First Aid Service is available on request or emergency</li>
                            </ul>
                        </div>
                    </div>

                    <div class="overall-txt-policy">
                        <h3>Return & Refund</h3>
                        <div class="list-style-policy">
                            <ul>
                                <li>
                                    Once purchased, tickets will not be returned, cancelled or
                                    refunded
                                </li>
                                <li>
                                    Management won’t be responsible for any compensation or refund
                                    if the session mentioned on the ticket is not attended
                                </li>
                                <li>
                                    If, for any reason, the session is not exhibited on the
                                    mentioned date and time on the ticket then you will be entitled
                                    to obtain a refund or exchange as per availability
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Safety