import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'jquery-ui/ui/widgets/datepicker';
import { fetchProductKarachi, postBookingData, placeOrder } from '../../../redux/Actions/MainAction';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const BookingSys = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [standardPassCount, setStandardPassCount] = useState(0);
  const [premiumPassCount, setPremiumPassCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formatedDate, setFormatedDate] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const dateParam = urlParams.get('date');
    const passParam = urlParams.get('pass');
    const personsParam = urlParams.get('persons');

    if (dateParam) {
      setSelectedDate(dateParam);
      // Update selected date in the datepicker
      $("#calendar").datepicker("setDate", new Date(dateParam));
    }

    if (passParam === 'standardpass' && personsParam) {
      setStandardPassCount(parseInt(personsParam));
    } else if (passParam === 'premiumpass' && personsParam) {
      setPremiumPassCount(parseInt(personsParam));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(fetchProductKarachi());
  }, [dispatch]);

  const chkKarachi = useSelector((state) => state.chkKarachi.chkKarachi);

  useEffect(() => {
    if (chkKarachi.length > 0) {
      const totalAmount = (standardPassCount * chkKarachi[0]?.price) + (premiumPassCount * chkKarachi[1]?.price);
      setTotalAmount(totalAmount);
    }
  }, [chkKarachi, standardPassCount, premiumPassCount]);

  useEffect(() => {
    $(document).ready(() => {
      // Initialize datepicker
      $("#calendar").datepicker({
        inline: true,
        showOtherMonths: true,
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        onSelect: (date) => handleDateSelect(date), // Update selected date
        beforeShowDay: function(date) {
          const formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
          if (formattedDate === selectedDate) {
            return [true, 'selected-date'];
          }
          return [true, ''];
        }
      });

      // Initialize magnific popup
      $(".popup-with-move-anim").magnificPopup({
        type: "inline",
        fixedContentPos: false,
        fixedBgPos: true,
        overflowY: "auto",
        closeBtnInside: false,
        preloader: false,
        midClick: true,
        removalDelay: 300,
        mainClass: "my-mfp-slide-bottom",
        closeOnBgClick: false,
        callbacks: {
          open: function () {
            // Bind the event handler to the button click event
            $('.mfp-content .button').off('click').on('click', handlePlaceOrder);
          }
        }
      });
    });
  }, [dispatch]);

  const handlePassMinus = (type) => {
    if (type === 'standard') {
      setStandardPassCount((prevCount) => Math.max(0, prevCount - 1));
    } else if (type === 'premium') {
      setPremiumPassCount((prevCount) => Math.max(0, prevCount - 1));
    }
  };

  useEffect(() => {
    // Make sure the DOM elements are loaded before accessing them
    document.getElementById('listHeading1').textContent = `Standard Pass ${standardPassCount || 0}x`;
    document.getElementById('listHeading2').textContent = `Premium Pass ${premiumPassCount || 0}x`;
  }, [standardPassCount, premiumPassCount]);

  const handlePassPlus = (type) => {
    if (type === 'standard') {
      setStandardPassCount((prevCount) => prevCount + 1);
      $('#listHeading1').text(`Standard Pass ${standardPassCount}x`);
    } else if (type === 'premium') {
      setPremiumPassCount((prevCount) => prevCount + 1);
      $('#listHeading2').text(`Premium Pass ${standardPassCount}x`);
    }
  };

  const handleTimeSlotSelect = (timeSlot, slotId) => {
    setSelectedTimeSlot(timeSlot);
    setSelectedTimeSlotId(slotId);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate) {
      const parsedDate = new Date(selectedDate);
      const timeZoneOffset = parsedDate.getTimezoneOffset(); // Get time zone offset in minutes
      const adjustedDate = new Date(parsedDate.getTime() - timeZoneOffset * 60000); // Adjust the date by subtracting the time zone offset
      const formattedDate = adjustedDate.toISOString();
      setFormatedDate(formattedDate);
    }
  }, [selectedDate]);

  const handleBooking = async () => {
    // Check if selectedTimeSlotId or formatedDate is missing
    if (!selectedTimeSlotId) {
      toast.error("Slot Time is Required !");
      $.magnificPopup.close();
      return;
    }
    if (!formatedDate) {
      toast.error("Date is Required !");
      $.magnificPopup.close();
      return;
    }

    // Check if both standardPassCount and premiumPassCount are 0
    if (standardPassCount === 0 && premiumPassCount === 0) {
      toast.error("Please select at least one item!");
      $.magnificPopup.close();
      return;
    }

    // Proceed with booking if no errors
    await dispatch(postBookingData({
      slotDate: formatedDate,
      slotId: selectedTimeSlotId,
      totalAmount,
      standardPass: {
        qty: standardPassCount,
        productId: chkKarachi[0]?.productId,
        price: standardPassCount * chkKarachi[0]?.price
      },
      premiumPass: {
        qty: premiumPassCount,
        productId: chkKarachi[1]?.productId,
        price: premiumPassCount * chkKarachi[1]?.price
      }
    }));
  }

  
  const chkKarachiSlot = useSelector((state) => state.chkKarachi.chkKarachiSlot)
  const billing = useSelector((state) => state.billing.billing);

  const handlePlaceOrder = () => {
    const userName = $('#userNameInput').val();
    const mobileNumber = $('#mobileNumberInput').val();

    // Assuming placeOrderLah returns a Promise indicating the success/failure of the order placement
    dispatch(placeOrder(userName, mobileNumber))
      .then((success) => {
        if (success) {
          // Close the Magnific Popup
          $.magnificPopup.close();

          // Clear input fields
          $('#userNameInput').val('');
          $('#mobileNumberInput').val('');
        } else {
          console.log("Failed!");
        }
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });
  }


  return (
    <div className="booking-system">
      <div className="container">
        <div className="booking">
          <div className="bookingContainer">
            <div class="circleSmall one"></div>
            <div class="circleSmall two"></div>
            <div class="circleSmall three"></div>
            <div class="circleSmall four"></div>
            <div class="calenderContainer">
              <div id="calendar"></div>
            </div>
            <div className="Bookings">
              <div className="PassContainer">
                {chkKarachi.length === 0 ? (
                  <div>Loading...</div>
                ) : (
                  chkKarachi.map((item, index) => (
                    <div className="standardPass" key={index}>
                      <div className="passContent">
                        <h2 className="passHeading">{item.productName}</h2>
                        <p className="priceTag" id={index}>Rs {item.price}</p>
                      </div>
                      <div className="number">
                        <span className="minus" onClick={() => handlePassMinus(item.productName === 'Standard Ticket' ? 'standard' : 'premium')}>
                          <i className="fa-sharp fa-solid fa-minus"></i>
                        </span>
                        <input
                          type="text"
                          className={index}
                          value={item.productName === 'Standard Ticket' ? standardPassCount : item.productName === 'Premium Ticket' ? premiumPassCount : 0}
                          readOnly
                        />
                        <span className="plus" onClick={() => handlePassPlus(item.productName === 'Standard Ticket' ? 'standard' : 'premium')}>
                          <i className="fa-sharp fa-solid fa-plus"></i>
                        </span>
                      </div>
                    </div>
                  ))
                )}
                <div class="timeSlot">
                  <h3 class="timeSlotHeading">Select Time Slot:</h3>
                  <div class="radioContainer">
                    {chkKarachiSlot.length === 0 ? (
                      <div>Loading...</div>
                    ) : (
                      chkKarachiSlot.map((item, index) => (
                        <div key={index}>
                          <input type="radio" id={item.slotTime} name="timeSlot" value={item.slotTime} onChange={() => handleTimeSlotSelect(item.slotTime, item.slotId)} />
                          <label htmlFor={item.slotTime}>{item.slotTime}</label>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ticketContainer">
            <div className="ticketHeader">
              <div class="circleSmall five"></div>
              <div class="circleSmall six"></div>
              <div class="circleSmall seven"></div>
              <h2 className="ticketHeading">Your Ticket</h2>
            </div>
            <div className="ticketCenter">
              <ul>
                <li className="listContainer">
                  <div className="passList">
                    <h3 className="listHeading" id='listHeading1'></h3>
                    <h3 className="listValue">Rs. {standardPassCount * chkKarachi[0]?.price}</h3>
                  </div>
                </li>
                <li className="listContainer">
                  <div className="passList">
                    <h3 className="listHeading" id='listHeading2'></h3>
                    <h3 className="listValue">Rs. {premiumPassCount * chkKarachi[1]?.price}</h3>
                  </div>
                </li>
                <li className="listContainer">
                  <div className="passList">
                    <h3 className="listHeading">Booking Date</h3>
                    <h3 className="listValue">{selectedDate ? selectedDate : "mm-dd-yy"}</h3>
                  </div>
                </li>
                <li className="listContainer">
                  <div className="passList">
                    <h3 className="listHeading">Booking Time</h3>
                    <h3 className="listValue">{selectedTimeSlot ? selectedTimeSlot : "0:00"}</h3>
                  </div>
                </li>
              </ul>
              <h2 className="totalAmount">Your Bill RS. {standardPassCount * chkKarachi[0]?.price + premiumPassCount * chkKarachi[1]?.price}</h2>
            </div>
            <a className="popup-with-move-anim" href="#small-dialog" onClick={handleBooking}>
              <div className="ticketFooter">
                <div className="circleBig eight"></div>
                <div className="circleBig nine"></div>
                Confirm Booking
              </div>
            </a>
            <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
              <div className="popHeader">
                <div className="edit">
                  <i className="fa-sharp fa-regular fa-pen-to-square"></i>
                  <span>Edit Order</span>
                </div>
                <div className="heading">Proceed to Billing</div>
                <div className="closePop mfp-close" >
                  <i className="fa-sharp fa-thin fa-xmark"></i>
                </div>
              </div>
              <div className="popup-main">
                <div className="popup-info">
                  <h3>Personal Information:</h3>
                  <div className="popup-input">
                    <div className="inputText">
                      <input
                        type="text"
                        name=""
                        id="userNameInput"
                        placeholder="Your Name"
                        required
                      />
                      <span>* Please Fill This Field</span>
                    </div>
                    <div className="inputText">
                      <input
                        type="text"
                        name=""
                        id="mobileNumberInput"
                        placeholder="Mobile Number"
                        required
                      />
                      <span>* Please Fill This Field</span>
                    </div>
                  </div>
                  <div className="popup-list">
                    <div className="pop-heading">
                      <ul>
                        <li>Billing Details:</li>
                        <li>Amount</li>
                      </ul>
                    </div>
                    <div className="pop listdown">
                      <ul>
                        {billing.length === 0 ? (
                          <div>Loading...</div>
                        ) : (
                          <>
                            {billing.standardPass && (
                              <li>
                                <div className="left-txt-pop">Standard Pass {billing.standardPass.qty}x</div>
                                <div className="left-txt-pop">Rs {billing.standardPass.price}</div>
                              </li>
                            )}
                            {billing.premiumPass && (
                              <li>
                                <div className="left-txt-pop">Premium Pass {billing.premiumPass.qty}x</div>
                                <div className="left-txt-pop">Rs {billing.premiumPass.price}</div>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                    <div className="pop-heading">
                      <ul>
                        {billing.length === 0 ? (
                          <div>Loading...</div>
                        ) : (
                          <>
                            <li>
                              Sub Total:
                            </li>
                            <li>Rs. {billing.totalAmount}</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="pop-payment-list">
                    <h4>Payment Method:</h4>
                    <div className="cards-pay">
                      <label className="card1" htmlFor="apple">
                        <input type="radio" name="payMethod" id="apple" />
                        <img src="images/apple.png" alt="" />
                      </label>
                      <label className="card1" htmlFor="bitcoin">
                        <input type="radio" name="payMethod" id="bitcoin" />
                        <img src="images/bitcoin.png" alt="" />
                      </label>
                      <label className="card1" htmlFor="google">
                        <input type="radio" name="payMethod" id="google" />
                        <img src="images/google.png" alt="" />
                      </label>
                      <label className="card1" htmlFor="paypal">
                        <input type="radio" name="payMethod" id="paypal" />
                        <img src="images/paypal.png" alt="" />
                      </label>
                      <label className="card1" htmlFor="visa">
                        <input type="radio" name="payMethod" id="visa" />
                        <img src="images/visa.png" alt="" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="placeOrder button">Place Order</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingSys;

