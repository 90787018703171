import { combineReducers } from 'redux';


const checkOutKarachiInitialState = {
  chkKarachi: [],
  chkKarachiSlot: [],
  error: null
};
const checkOutLahoreInitialState = {
  chkLahore: [],
  chkLahoreSlot: [],
  error: null
};
const postBookingInitialState = {
  billing: [],
  error: null
};
const postBookingLahInitialState = {
  billingLah: [],
  error: null
};

const placeOrderInitialState = {
  link:[],
  error: null
}
const placeOrderLahInitialState = {
  link:[],
  error: null
}

const checkoutKarachi = (state = checkOutKarachiInitialState, action) => {
  switch (action.type) {
    case 'GET_CHECKOUT_KARACHI_SUCCESS':
      return { ...state, chkKarachi: action.payload, error: null };
    case 'GET_CHECKOUT_KARACHI_FAILURE':
      return { ...state, error: action.payload, chkKarachi: [] };
    case 'GET_CHECKOUT_KARACHI_SLOT_SUCCESS':
      return { ...state, chkKarachiSlot: action.payload, error: null };
    case 'GET_CHECKOUT_KARACHI_SLOT_FAILURE':
      return { ...state, error: action.payload, chkKarachiSlot: [] };
    default:
      return state;
  }
};
const checkoutLahore = (state = checkOutLahoreInitialState, action) => {
  switch (action.type) {
    case 'GET_CHECKOUT_LAHORE_SUCCESS':
      return { ...state, chkLahore: action.payload, error: null };
    case 'GET_CHECKOUT_LAHORE_FAILURE':
      return { ...state, error: action.payload, chkLahore: [] };
    case 'GET_CHECKOUT_LAHORE_SLOT_SUCCESS':
      return { ...state, chkLahoreSlot: action.payload, error: null };
    case 'GET_CHECKOUT_LAHORE_SLOT_FAILURE':
      return { ...state, error: action.payload, chkLahoreSlot: [] };
    default:
      return state;
  }
};
const postBooking = (state = postBookingInitialState, action) => {
  switch (action.type) {
    case 'POST_BOOKING_SUCCESS':
      return { ...state, billing: action.payload, error: null };
    case 'POST_BOOKING_FAILURE':
      return { ...state, error: action.payload, billing: [] };
    default:
      return state;
  }
};

const postBookingLah = (state = postBookingLahInitialState, action) => {
  switch (action.type) {
    case 'POST_BOOKING_LAHORE_SUCCESS':
      return { ...state, billingLah: action.payload, error: null };
    case 'POST_BOOKING_LAHORE_FAILURE':
      return { ...state, error: action.payload, billingLah: [] };
    default:
      return state;
  }
};

//   switch (action.type) {
//     case 'GET_ROLE_SUCCESS':
//       return { ...state, role: action.payload, error: null };
//     case 'GET_ROLE_FAILURE':
//       return { ...state, error: action.payload, role: null };
//     case 'ADD_ROLE_SUCCESS': {
//       // Append the newly added role to the existing roles
//       const updatedRoles = [...state.role, action.payload];
//       return { ...state, role: updatedRoles, error: null };
//     }
//     case 'ADD_ROLE_FAILURE':
//       return { ...state, error: action.payload }; // Update state as needed
//     case 'DELETE_ROLE_SUCCESS': {
//       // Filter out the deleted role from the existing roles
//       const updatedRoles = state.role.filter(role => role.id !== action.payload);
//       return { ...state, role: updatedRoles, error: null };
//     }
//     case 'DELETE_ROLE_FAILURE':
//       return { ...state, error: action.payload }; // Update state as needed
//     default:
//       return state;
//   }
// };



// Combine both reducers into a single root reducer

const placeOrder = (state = placeOrderInitialState, action) => {
  switch (action.type) {
    case 'PLACE_ORDER_SUCCESS':
      return { ...state, link: action.payload, error: null };
    case 'PLACE_ORDER_FAILURE':
      return { ...state, error: action.payload, link: null };
    default:
      return state;
  }
};
const placeOrderLah = (state = placeOrderLahInitialState, action) => {
  switch (action.type) {
    case 'PLACE_ORDER_LAHORE_SUCCESS':
      return { ...state, link: action.payload, error: null };
    case 'PLACE_ORDER_LAHORE_FAILURE':
      return { ...state, error: action.payload, link: null };
    default:
      return state;
  }
};


const rootReducer = combineReducers({
  chkKarachi: checkoutKarachi,
  chkLahore: checkoutLahore,
  billing: postBooking,
  billingLah: postBookingLah,
  link: placeOrder,
  link:placeOrderLah,
});

export default rootReducer;
